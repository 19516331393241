import React, { useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import "./NavBar.css"; // Ensure this matches the file name for CSS if applicable
import logo from "../assets/img/reacirc-logo.png"; // Adjust the path based on where you place your logo

const NavBar = ({ handleSectionChange }) => {
  const [selectedItem, setSelectedItem] = useState("products"); // Default to 'products'

  const handleNavClick = (section) => {
    setSelectedItem(section);
    handleSectionChange(section);
  };

  return (
    <Navbar sx={{ padding: "1rem" }} bg="primary" variant="dark" expand="sm">
      <Navbar.Brand href="#">
        <img
          src={logo}
          alt="REACirc Logo"
          className="navbar-logo"
          style={{ width: "40px", height: "40px", marginRight: "10px" }} // Adjust size and margin as needed
        />
        REACirc <span className="pronunciation">/Rhea-sirk/</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav">
        <Nav className="mr-auto">
          <Nav.Link
            onClick={() => handleNavClick("products")}
            className={selectedItem === "products" ? "active" : ""}
          >
            Products
          </Nav.Link>
          <Nav.Link
            onClick={() => handleNavClick("pricing")}
            className={selectedItem === "pricing" ? "active" : ""}
          >
            Pricing
          </Nav.Link>
          <Nav.Link
            onClick={() => handleNavClick("contact")}
            className={selectedItem === "contact" ? "active" : ""}
          >
            Contact
          </Nav.Link>
          <Nav.Link
            onClick={() => handleNavClick("about")}
            className={selectedItem === "about" ? "active" : ""}
          >
            About Us
          </Nav.Link>
        </Nav>
        <Nav className="ml-auto">
          <Button
            variant="outline-light"
            href="https://rlp.reacirc.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login to REACirc
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
